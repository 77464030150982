export interface ItemProps {
  emoji: string
  title: string
  href: string
}

export default [
  {
    emoji: "🎵",
    title: "Listeners Playlist",
    href: "https://lp.anzi.kr/",
  },
  {
    emoji: "📼",
    title: "Youtube",
    href: "https://www.youtube.com/user/anziart",
  },
  {
    emoji: "🎨",
    title: "Portfolio",
    href: "https://portfolio.anzi.kr/",
  },
  {
    emoji: "💎",
    title: "Behance",
    href: "https://www.behance.net/anzi",
  },
  {
    emoji: "📱",
    title: "Instagram",
    href: "https://www.instagram.com/anjihhg/",
  },
  {
    emoji: "📮",
    title: "Email",
    href: "mailto:anjihhg@gmail.com",
  },
] as ItemProps[]
