import React from "react"
import Header from "./Header"
import StyledWrapper from "./style"
import List from "./List"

export default () => {
  return (
    <StyledWrapper className="home">
      <Header />
      <List />
    </StyledWrapper>
  )
}
