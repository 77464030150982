import styled from "styled-components"

export default styled.button`
  position: relative;
  cursor: pointer;

  .clickableDom {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    background-color: rgba(0, 0, 0, 0.5);
  }
`
