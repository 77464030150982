import styled from "styled-components"
import AZClickable from "../../../../components/AZClickable"

export default styled(AZClickable)`
  display: flex;
  align-items: center;
  width: 100vw;
  max-width: 440px;
  min-width: 300px;
  height: 60px;
  font-weight: 600;
  background-color: rgba(0, 0, 0, 0.02);
  margin-bottom: 10px;
  padding: 0 24px;
  position: relative;

  .emoji {
    margin-right: 24px;
    font-size: 22px;
  }

  .arrow {
    position: absolute;
    right: -12px;
    padding: inherit;
  }

  @media only screen and (max-width: 600px) {
    width: calc(100vw - 48px);
    margin-bottom: 8px;
  }
`
