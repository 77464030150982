import styled from "styled-components"

export default styled.header`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .stagger {
    visibility: hidden;
  }
  .thumb {
    box-shadow: 0 12px 16px 0 rgba(0, 0, 0, 0.08),
      inset 0 0 0 1px rgba(0, 0, 0, 0.03);
    border-radius: 12px;
    width: 60px;
    height: 60px;
    margin-top: 15vh;
    visibility: hidden;
  }

  .title {
    font-size: 18px;
    line-height: 26px;
    font-weight: 700;
    margin-top: 48px;
  }

  .subtitle {
    font-size: 12px;
    font-weight: 700;
    margin-top: 12px;
    line-height: 19px;
  }
  .content {
    font-size: 12px;
    font-weight: 600;
    max-width: 440px;
    line-height: 19px;
    letter-spacing: -0.005em;
  }

  .divider {
    background: rgba(0, 0, 0, 0.5);
    width: 32px;
    height: 1px;
    margin: 32px auto;
  }
`
