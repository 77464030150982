import React, { HTMLAttributes, useRef } from "react"
import gsap from "gsap"
import { isMobile } from "react-device-detect"
import StyledWrapper from "./style"

interface Props extends HTMLAttributes<HTMLButtonElement> {
  clickableProps?: HTMLAttributes<HTMLDivElement>
  scaleEffect?: boolean
  href?: string
}

export default ({
  scaleEffect = false,
  href,
  clickableProps,
  children,
  ...props
}: Props) => {
  const ref = useRef(null)

  const handleEvent = (e: React.MouseEvent | React.TouchEvent) => {
    const { type } = e
    let { current } = ref
    gsap.killTweensOf(current)

    const complete = () => {
      gsap.set(current, { clearProps: "opacity, scale" })
    }

    if (!isMobile) {
      if (type === "mouseenter") {
        gsap.set(current, {
          opacity: 0,
          scale: scaleEffect ? 0.8 : undefined,
        })
        gsap.to(current, {
          duration: 0.5,
          opacity: 0.15,
          scale: scaleEffect ? 1 : undefined,
          ease: "elastic.out(1, 0.8)",
        })
      } else if (type === "mouseleave") {
        gsap.to(current, {
          duration: 0.3,
          opacity: 0,
          scale: scaleEffect ? 0.95 : undefined,
          onComplete: complete,
        })
      } else if (type === "mousedown") {
        gsap.to(current, {
          duration: 0.5,
          opacity: 0.1,
          ease: "elastic.out(1, 0.8)",
          scale: scaleEffect ? 0.98 : 1,
        })
      } else if (type === "mouseup") {
        gsap.to(current, {
          duration: 0.3,
          opacity: 0.15,
          scale: scaleEffect ? 1 : undefined,
        })
      }
    } else {
      if (type === "touchstart") {
        gsap.set(current, {
          opacity: 0.15,
        })
      } else if (type === "mouseup" || type === "touchend") {
        gsap.to(current, {
          duration: 0.4,
          opacity: 0,
          onComplete: complete,
        })
      }
    }
  }

  const button = () => {
    return (
      <StyledWrapper
        onMouseEnter={handleEvent}
        onMouseLeave={handleEvent}
        onMouseDown={handleEvent}
        onMouseUp={handleEvent}
        onTouchStart={handleEvent}
        onTouchEnd={handleEvent}
        {...props}
      >
        {children}
        <div className="clickableDom" ref={ref} {...clickableProps}></div>
      </StyledWrapper>
    )
  }

  return <>{href ? <a href={href}>{button()}</a> : button()}</>
}
