import styled from "styled-components"

export default styled.div`
  text-align: center;
  padding: 20px;
  box-sizing: border-box;
  margin-bottom: 120px;
  width: 100%;

  @media only screen and (max-width: 600px) {
    padding: 20px 28px;
  }
`
