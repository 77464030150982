import React, { useRef, useEffect } from "react"
import thumb from "../../../img/thumb.jpg"
import StyledHeader from "./style"
import gsap from "gsap"

export default () => {
  const imgRef = useRef(null)
  const staggerRef = useRef(null)

  useEffect(() => {
    const img = imgRef.current
    const staggerCurrent = staggerRef.current! as HTMLElement
    const staggerItems = Array.from(staggerCurrent.children)

    gsap.set(img, { visibility: "visible" })
    gsap.from(img, {
      duration: 1,
      scale: 0.3,
      opacity: 0,
      ease: "expo.out",
      borderRadius: 0,
      boxShadow: "0 0 0 0 rgba(0,0,0,0)",
      delay: 0.4,
    })

    let _d = 0.2
    gsap.set(staggerCurrent, { visibility: "visible" })
    staggerItems.map((item, i) => {
      gsap.from(item, {
        duration: 1.3,
        opacity: 0,
        y: 72,
        scale: 1.5,
        color: "orange",
        delay: _d,
        ease: "expo.out",
      })
      _d += 0.08
    })

    return () => {
      gsap.killTweensOf(imgRef)
    }
  }, [imgRef])

  return (
    <StyledHeader>
      <img ref={imgRef} src={thumb} className="thumb" />
      <div ref={staggerRef} className="stagger">
        <div className="title">
          Jiyong Ahn
          <br />
          Designer. UX Engineer
        </div>
        <div className="subtitle">
          Also known as ANZI
          <br />
          Seoul, South Korea
        </div>
        <div className="divider"></div>
        <div className="content">
          Ahn's work includes a wide range of fields, reflecting his abilities
          in graphic design, web-based design, UI design, motion graphic design,
          typography and many other fields. Recently, He is interested in iOS,
          web developent to create the actual product.
        </div>
      </div>
    </StyledHeader>
  )
}
