import styled from "styled-components"

export default styled.div`
  margin-top: 48px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .item {
    visibility: hidden;
  }
`
