import React, { useRef, useEffect } from "react"
import StyledWrapper from "./style"
import items from "./items"
import Item from "./Item"
import gsap from "gsap"

export default () => {
  const itemsRef = Array.from({ length: items.length }, a => useRef(null))

  useEffect(() => {
    const items = itemsRef.map(item => item.current! as HTMLElement)

    gsap.set(items, { visibility: "visible" })
    gsap.from(items, {
      duration: 1,
      opacity: 0,
      y: 100,
      ease: "expo.out",
      delay: 0.5,
      scaleX: 1.5,
      stagger: 0.07,
    })

    return () => {}
  }, [itemsRef])

  return (
    <StyledWrapper>
      {items.map((item, i) => {
        return (
          <div className="item" key={i} ref={itemsRef[i]}>
            <Item {...item} />
          </div>
        )
      })}
    </StyledWrapper>
  )
}
