import React from "react"
import "./App.css"
import thumb from "./img/thumb.jpg"
import Home from "./pages/Home"

function App() {
  return <Home />
}

export default App
