import React from "react"
import { ItemProps } from "../items"
import StyledWrapper from "./style"

interface Props extends ItemProps {}

export default ({ emoji, title, href, ...props }: Props) => {
  return (
    <StyledWrapper
      scaleEffect={true}
      style={{ borderRadius: "12px" }}
      href={href}
      clickableProps={{ style: { borderRadius: 12 } }}
      {...props}
    >
      <div className="emoji">{emoji}</div>
      <div className="title">{title}</div>

      <svg className="arrow" width="24px" height="24px" viewBox="0 0 24 24">
        <g strokeWidth="1" fill="none" fillRule="evenodd">
          <polyline
            id="Path"
            stroke="rgba(0,0,0,0.2)"
            opacity="0.5"
            points="9 5 16 12 9 19"
          ></polyline>
        </g>
      </svg>
    </StyledWrapper>
  )
}
